<template>
  <section :class="[$options.name]">
    <slot/>
  </section>
</template>

<script>
  export default {
    name: 'section-container',
    setup () {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .section-container {
    --height: 100vh;
  }

  .section-container {
    width: 100%;
    height: var(--height);
    position: relative;
  }
</style>
