<template>
  <main :class="[$options.name]">
    <slot/>
  </main>
</template>

<script>
  export default {
    name: 'view-container',
    setup () {
      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .view-container {
    --padding: 60px var(--gutter) 0 var(--gutter);

    @include from ('md') {
      --padding: 0 10% 0 10%;
    }
  }

  .view-container {
    width: 100%;
    padding: var(--padding);
    position: relative;
  }
</style>
