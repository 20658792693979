<template>
  <div :class="[$options.name]" :style="style">
    <div class="items">
      <transition-group name="slide">
        <template v-for="(item, index) in items">
          <div class="item" v-if="active === index" :key="index">
            <slot name="item" :item="item" :index="index">
              <img :src="item" :alt="'item' + index" :ratio="ratio" :observe="false"/>  
            </slot>
          </div>  
        </template>  
      </transition-group>  
    </div>
    <div class="puces" v-if="items.length > 1">
      <span v-for="(item, index) in items" :key="index" :class="{ active: index <= active, animate: index === active }"></span>
    </div>
    <div class="controls">
      <touch class="back" @tap="back" :animate="false"></touch>
      <touch class="next" @tap="next" :animate="false"></touch>
    </div>
  </div>  
</template>

<script>
import { ref, computed, onMounted } from 'vue'

import Touch from './Touch'

import useInterval from '../composables/useInterval'

export default {
  name: 'carousel',
  components: {
    Touch
  },
  props: {
    items: { type: Array, default: () => [] },
    ratio: { type: [String, Number], default: 1 },
    duration: { type: Number, default: 5000 },
    auto: { type: Boolean, default: true }
  },
  setup (props, { emit }) {
    const active = ref(-1) 
    const direction = ref('left')

    const style = computed(() => ({
      '--duration': `${props.duration / 1000}s`
    }))

    function back () {
      direction.value = 'right'
      let next = (active.value - 1) % props.items.length
      if (next < 0) next = props.items.length - 1
      active.value = next

      start() 
    }

    function next () {
      direction.value = 'left'  
      active.value = (active.value + 1) % props.items.length 

      start() 
    }

    const { start, stop } = useInterval(() => {
      direction.value = 'left' 

      let next = (active.value + 1) % props.items.length
      if (next === 0) active.value = -1

      active.value = next

      emit('changed', next)
    }, props.duration, false)

    function reset () {
      active.value = 0
    }

    onMounted(() => {
      active.value = 0

      if (props.auto) start()
    })

    return {
      active,direction, style,
      back, next, start, stop, reset
    }  
  }  
}
</script>

<style lang="scss" scoped>
  .carousel {
    --duration: 5;
  }  

  .carousel {
    position: relative;
    width: calc(100% + var(--gutter) * 2);
    margin: 0 calc(var(--gutter) * -1);

    .items {
      img {
        border-radius: 0;
      }
    }

    .puces {
      position: absolute;
      bottom: var(--gutter);
      width: calc(100% - var(--gutter) * 2 + var(--gutter) / 3);
      margin: 0 calc(var(--gutter) - var(--gutter) / 6);
      display: flex;
      flex-direction: row;
      z-index: 1;     
      display: none;
      
      span {
        flex: 1;
        display: flex;
        background: var(--light-25);
        height: 2px;
        margin: 0 calc(var(--gutter) / 6); 
        position: relative;
        overflow: hidden;
        border-radius: var(--corner-width);
        transform: translateZ(0);

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--light);
          opacity: 0.5;
          transform: translate3d(-100%, 0, 0);
        }

        &.active {
          &:after {
            transform: translate3d(0, 0, 0);
          } 
        }

        &.animate {
          &:after {
            animation: fill var(--duration) linear;
          }             
        }
      }
    }

    .controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;   
      display: flex;
      flex-direction: row;
      
      .touch {
        flex: 1;
        background: var(--light);
        opacity: 0;
        transition: opacity 0.25s ease-in;

        &.pressed {
          opacity: 0.05;
        }
      }
    }

    .item {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .slide-move, /* apply transition to moving elements */
    .slide-enter-active,
    .slide-leave-active {
      transition: all 0.5s ease;
    }

    .slide-enter-from,
    .slide-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
    .slide-leave-active {
      position: absolute;
    }

    @keyframes fill {
      0% {
        transform: translate3d(-100%, 0, 0); 
      } 
      100% {
        transform: translate3d(0, 0, 0);  
      } 
    }
  }  
</style>