<template>
  <view-container :class="[$options.name]">
    <section-container class="heading">
      <h1 class="hero" v-html="t(hero)"></h1>
      <p class="slogan" v-html="t(slogan)"></p>
      <form class="form">
        <input class="input" v-model="email" :placeholder="t(placeholder)">
        <touch @tap.prevent="send">{{ t(submit) }}</touch>
      </form>
      <p class="notice small">{{ t(notice) }}</p>
      <img src="@/assets/images/arrow.svg" alt="Arrow" class="arrow">
    </section-container>
    <section-container ref="container" id="features" class="features">
      <div class="circles">
        <div class="circle" 
          v-for="(item, index) of circles" :key="index"
          :class="[item.type, item.color]" :style="{ '--size': `${item.size}${item.type === 'emoji' ? 'rem' : 'px'}` }"
        >
          <span v-if="item.type === 'emoji'">{{ item.text }}</span>
        </div>
      </div>
      <div class="demo">
        <div ref="device" class="device" :style="style">
          <carousel ref="carousel" :auto="false" :duration="5000" :items="medias" :class="{ scale }" @changed="changed"/>
          <img src="@/assets/images/frame-no-notch.svg" alt="Frame" class="frame">
        </div>
        <div class="feature" :class="[{ active: !feature?.hide }, feature?.alignment]">
          <h3 class="title">{{ feature?.title }}</h3>
          <p>{{ feature?.description }}</p>
        </div>
      </div>
    </section-container>
    <!--<section-container class="reviews"></section-container>-->
  </view-container>
</template>

<script>
  import { ref, computed, onMounted } from 'vue'
  import { useI18n } from 'i18n'

  import useElementBounding from '@/libs/foundation/composables/useElementBounding'

  import Touch from '@/libs/foundation/components/Touch'
  import Carousel from '@/libs/foundation/components/Carousel'

  import ViewContainer from '@/components/container/ViewContainer'
  import SectionContainer from '@/components/container/SectionContainer'

  import { v4 as uuidv4 } from 'uuid'

  export default {
    name: 'home',
    props: {
      y: { type: Number, default: 0 }
    },
    components: {
      Touch,
      Carousel,
      ViewContainer,
      SectionContainer
    },
    setup (props) {
      const { t } = useI18n()

      const hero = 'modules.portal.home.heading.hero'
      const slogan = 'modules.portal.home.heading.slogan'
      const placeholder = 'modules.portal.home.heading.placeholder'
      const submit = 'modules.portal.home.heading.submit'
      const notice = 'modules.portal.home.heading.notice'

      const email = ref('')

      const locales = computed(() => ({
        email: t('modules.portal.home.form.ok') 
      }))

      async function send () {
        if (email.value.length > 2) {
          // console.log(email.value, ip.value, country.value, city.value, uuid.value)

          const input = {
            email: email.value, 
            ip: ip.value, 
            country: country.value, 
            city: city.value, 
            uuid: uuid.value 
          }

          const created = await create(input)

          if (created) {
            email.value = locales.value.email

            setTimeout(() => {
              email.value = ''
            }, 3000)
          } else {
            console.log('Already exists!')
          }
        }
      }

      async function create (input) {
        try {
          const response = await fetch(`${process.env.VUE_APP_HTTP_URL}/email`, {
            method: 'post',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(input)
          })
          const json = await response.json()

          return json.data
        } catch (e) {
          console.error(e)
          return false
        }
      }

      const container = ref(null)
      const device = ref(null)
      const video = ref(null)
      const ready = ref(null)
      const position = ref(0)

      const { height: containerHeight } = useElementBounding(container)

      const style = computed(() => {
        if (props.y < (containerHeight.value / 2)) {
          position.value = props.y

          if (isPlaying.value) {
            isPlaying.value = false

            carousel.value?.stop()

            reset()
          }

          carousel.value?.reset()

        } else {
          position.value = containerHeight.value / 2

          if ((props.y + 100) >= containerHeight.value) {
            if (ready.value) {
              if (!isPlaying.value) {
                isPlaying.value = true

                carousel.value?.start()
                
                loop()
              }
            } else {
              if (ready.value === null) ready.value = false
              else ready.value = true
            }
          }
        }

        return {
          '--y': `${position.value}px`
        }
      })

      const isPlaying = ref(false)

      const carousel = ref(null)

      const medias = [
        require('@/assets/images/features/home.png'),
        require('@/assets/images/features/places.png'),
        require('@/assets/images/features/place.png'),
        require('@/assets/images/features/create-offer.png'),
        require('@/assets/images/features/offers.png'),
        require('@/assets/images/features/offer.png'),
        require('@/assets/images/features/filter.png')
      ]

      const ratio = ref('1')

      const scale = ref(false)

      function changed () {
        scale.value = false

        setTimeout(() => {
          scale.value = true
        }, 100)
      }

      onMounted(() => {
        changed()
      })

      const features = computed(() => [
        { title: t('modules.portal.home.features.feature-home'), description: t('modules.portal.home.features.feature-home-description'), duration: 5000, alignment: 'right' },
        { title: t('modules.portal.home.features.feature-places'), description: t('modules.portal.home.features.feature-places-description'), duration: 5000, alignment: 'left' },
        { title: t('modules.portal.home.features.feature-place'), description: t('modules.portal.home.features.feature-place-description'), duration: 5000, alignment: 'right' },
        { title: t('modules.portal.home.features.feature-create-offer'), description: t('modules.portal.home.features.feature-create-offer-description'), duration: 5000, alignment: 'left' },
        { title: t('modules.portal.home.features.feature-offers'), description: t('modules.portal.home.features.feature-offers-description'), duration: 5000, alignment: 'right' },
        { title: t('modules.portal.home.features.feature-offer'), description: t('modules.portal.home.features.feature-offer-description'), duration: 5000, alignment: 'left' },
        { title: t('modules.portal.home.features.feature-filters'), description: t('modules.portal.home.features.feature-filters-description'), duration: 5000, alignment: 'right' }
      ])

      const feature = ref(null) 
      const current = ref(-1)
      const timeoutFade = ref(null)
      const timeout = ref(null)

      function loop () {
        current.value = (current.value + 1) % features.value.length
        feature.value = { ...features.value[current.value] }

        timeoutFade.value = setTimeout(() => {
          feature.value.hide = true
          feature.value.alignment = ''
        }, feature.value.duration - 1000)

        timeout.value = setTimeout(() => {
          loop()
        }, feature.value.duration)
      }

      function reset () {
        if (timeoutFade.value) clearTimeout(timeoutFade.value)
        timeoutFade.value = null

        if (timeout.value) clearTimeout(timeout.value)
        timeout.value = null

        feature.value = null
        current.value = -1
      }

      const circles = [
        { type: 'gradient', color: 'secondary', size: 15 },
        { type: 'border', color: 'primary', size: 10 },
        { type: 'border', color: 'primary', size: 10 },
        { type: 'border', color: 'secondary', size: 15 },
        { type: 'border', color: 'secondary', size: 5 },
        { type: 'gradient', color: 'primary', size: 15 },
        { type: 'emoji', text: '🍆', size: 2.5 },
        { type: 'emoji', text: '🍑', size: 3 },
        { type: 'emoji', text: '🍌', size: 2.25 },
        { type: 'emoji', text: '🔞', size: 2.25 }
      ]

      const getLocation = async () => {
        try {
          const response = await fetch('https://api.db-ip.com/v2/free/self')
          const json = await response.json()

          return json
        } catch (error) {
          console.error(`Error getting location`, error)
        }
      }

      const ip = ref(null)
      const country = ref(null)
      const city = ref(null)

      const uuid = ref(null)

      onMounted(async () => {
        const location = await getLocation()   
        
        ip.value = location.ipAddress
        country.value = location.countryCode
        city.value = location.city

        if (!localStorage.getItem('uuid')) {
          uuid.value = uuidv4()
          localStorage.setItem('uuid', uuid.value)
        } else {
          uuid.value = localStorage.getItem('uuid')
        }
      })

      return {
        t,
        hero, slogan, placeholder, submit, notice,
        email, send,
        container, device, video, style,
        circles, 
        feature,
        carousel,
        medias, ratio, scale, changed
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    --y: 0;

    --heading-padding: 0 5% 10% 5%;

    --heading-hero-font-size: calc(var(--font-size) * 1.6);
    --heading-slogan-max-width: 100%;
    --heading-slogan-font-size: calc(var(--font-size) * 1);

    --heading-form-flex-direction: column;
    --heading-form-touch-margin: calc(var(--gutter) / 2) 0 0 0;

    --device-frame-width: 252px;

    @include from ('sm') {
      --heading-padding: 0 10% 10% 10%;

      --heading-hero-font-size: calc(var(--font-size) * 2.2);
    }
    @include from ('md') {
      --heading-padding: 5% 10% 10% 10%;

      --heading-hero-font-size: calc(var(--font-size) * 2.6);
      --heading-slogan-max-width: 65%;

      --heading-form-flex-direction: row;
      --heading-form-touch-margin: 0 0 0 calc(var(--gutter) / 2);
    }
    @include from ('lg') {
      --heading-padding: 5% 20% 8vh 20%;

      --heading-hero-font-size: calc(var(--font-size) * 2.4);
      --heading-slogan-font-size: calc(var(--font-size) * 1);
    }
    @include from ('xl') {
      --heading-padding: 5% 25% 12vh 25%;

      --heading-hero-font-size: calc(var(--font-size) * 3.1);
      --heading-slogan-font-size: calc(var(--font-size) * 1.4);

      --device-frame-width: 310px;
    }
  }

  .home {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: var(--heading-padding);

      .hero {
        font-size: var(--heading-hero-font-size);
        :deep(b) {
          &[primary] {
            color: var(--primary);
          }
          &[secondary] {
            color: var(--secondary);
          }
        }
      }

      .slogan {
        max-width: var(--heading-slogan-max-width);
        margin: var(--gutter) 0;
        font-size: var(--heading-slogan-font-size);
      }

      .form {
        display: flex;
        flex-direction: var(--heading-form-flex-direction);
        margin: var(--gutter) 0 0 0;
        padding: calc(var(--spacing) / 2);
        background: var(--light);
        border-radius: calc(var(--corner-width) / 2);
        width: 600px;
        max-width: 100%;

        .input {
          color: var(--dark);
          flex: 1;

          &::placeholder {
            color: var(--grey-light);
          }

          &:focus::placeholder {
            color: transparent;
          }
        }

        .touch {
          flex-shrink: 0;
          margin: var(--heading-form-touch-margin);
          padding: calc(var(--spacing) / 2) calc(var(--spacing) * 2);
          color: var(--light);
          background: linear-gradient(120deg, var(--primary), var(--secondary));
          border-radius: calc(var( --corner-width) / 3);

          &:hover {
            background: linear-gradient(120deg, var(--primary-light), var(--secondary-light));
          }
        }
      }
    
      .notice {
        color: var(--tertiary);
        font-family: 'sf-pro-text-light';
        margin: calc(var(--gutter) / 2) 0 0 0; 
      }

      .arrow {
        margin: 20% 0 0 0;
        transform: translate3d(-15%, 0, 0);
      }
    }

    .features {
      display: flex;
      align-items: center;
      justify-content: center;

      .circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include to('sm') {
          top: -20%;
          left: -20%;
          width: 140%;
          height: 140%;
        }

        .circle {
          position: absolute;
          width: var(--size);
          height: var(--size);
          border-radius: 100%;
          animation: float 6s ease-in-out infinite;

          &.border {
            &.primary {
              border: 2px solid var(--primary);
            }
            &.secondary {
              border: 2px solid var(--secondary);
            }
          }

          &.gradient {
            &.primary {
              background: linear-gradient(120deg, var(--primary), transparent);
            }
            &.secondary {
              background: linear-gradient(120deg, var(--secondary), transparent);
            }
          }

          &.emoji {
            width: auto;
            height: auto;
            font-size: var(--size);
          }

          &:first-child {
            top: 15%;
            left: 25%;
          }
          &:nth-child(2) {
            top: 12.5%;
            right: 27.5%;
            animation-delay: 0.3s;
          }
          &:nth-child(3) {
            top: 45%;
            left: 12.5%;
            animation-delay: 0.6s;
          }
          &:nth-child(4) {
            top: 42.5%;
            right: 12.5%;
            animation-delay: 0.9s;
          }
          &:nth-child(5) {
            top: 75%;
            left: 20%;
            animation-delay: 1.2s;
          }
          &:nth-child(6) {
            top: 69.5%;
            right: 18.5%;
            animation-delay: 1.5s;
          }
          &:nth-child(7) {
            top: 20.5%;
            left: 12.5%;
            animation-delay: 0.3s;
          }
          &:nth-child(8) {
            top: 25.5%;
            right: 8.5%;
            animation-delay: 0.6s;
          }
          &:nth-child(9) {
            top: 60%;
            left: 8.5%;
            animation-delay: 0.9s;
          }
          &:last-child {
            top: 60.5%;
            right: 10.5%;
            animation-delay: 1.2s;
          }
        }
      }

      .demo {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 40vw;
          padding-bottom: 40vw;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          background: hsl(27deg, 43%, 96%);
          border-radius: 100%;
          opacity: 1;
        }

        .device {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          transform: translate3d(0, calc(-50vh + var(--y)), 0);
          overflow: hidden;

          .carousel {
            position: absolute;
            width: calc(var(--device-frame-width) - 26px);
            height: calc(100% - 22px);
            margin: 0 0 0 -2px;
            z-index: 1;
            border-radius: calc(var(--corner-width) * 1.45);
            overflow: hidden;
            background: var(--tertiary);

            :deep(img) {
              width: 100%;
            }

            @include from ('xl') {
              width: calc(var(--device-frame-width) - 31px);
              border-radius: calc(var(--corner-width) * 1.85);
            }
          }

          img {
            position: relative;
            z-index: 2;
            width: var(--device-frame-width);
          }
        }
      }

      .feature {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        opacity: 0;
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out 0.3s;

        @include to ('md') {
          display: none;
        }

        .title {
          color: var(--primary);
          margin: 0 0 calc(var(--gutter) / 2) 0;
          line-height: 1;
          font-size: calc(var(--font-size) * 2);
        }

        &.active {
          opacity: 1;
          transition: all 0.25s ease-in-out;
        }

        &.left {
          transform: translate3d(calc(-150% - var(--gutter)), -50%, 0);
        }

        &.right {
          transform: translate3d(calc(50% + var(--gutter)), -50%, 0);
        }
      }
    }

    @keyframes float {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
      100% {
        transform: translateY(0)
      }
    }
  }
</style>
