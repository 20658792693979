import { onUnmounted } from 'vue'

export default function useInterval (cb, interval = 1000, autoStart = false) {
  let timer = null

  function stop () {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  }

  function start () {
    stop()
    timer = setInterval(cb, interval)
  }

  if (autoStart) start()

  onUnmounted(stop)

  return { 
    start, 
    stop
  }
}