import { watch, unref, onUnmounted } from 'vue'

function unrefElement (elRef) {
  const plain = unref(elRef)
  return plain?.$el ?? plain
}

export default function useResizeObserver (target, callback, options = {}) {
  const { win = window, ...observerOptions } = options
  let observer

  const isSupported = win && 'ResizeObserver' in win

  const cleanup = () => {
    if (observer) {
      observer.disconnect()
      observer = undefined
    }
  }
  const stopWatch = watch(() => unrefElement(target), (el) => {
    cleanup()

    if (isSupported && win && el) {
      observer = new win.ResizeObserver(callback)
      observer.observe(el, observerOptions)
    }
  }, { immediate: true, flush: 'post' })

  const stop = () => {
    cleanup()
    stopWatch()
  }

  onUnmounted(stop)

  return {
    isSupported,
    stop
  }
}